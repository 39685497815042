
<template>
    <div>
        <page-files ref="page-files" @confirm="confirm" :limit="limit - binds.length" :appname="appname" :btnShow="false" />
        <div class="draggable-list">
            <draggable class="wrapper" v-model="binds">
                <transition-group>
                    <div class="item" v-for="(path,index) in binds" :key="path">
                        <img :src="loadImg(path)" />
                        <a href="javascript:void(0);" class="delete-btn" @click.stop="deleteImg(index)">
                            <i class="el-icon-close"></i>
                        </a>
                    </div>
                </transition-group>
            </draggable>
            <div v-if="limit > binds.length" class="item img-select" @click="$refs['page-files'].open()">
                <i class="el-icon-plus"></i>
            </div>
        </div>
    </div>
</template>

<script>
import draggable from 'vuedraggable'
export default {
    components:{draggable},
    name: "page-images",
    model: {
        prop: "value",
        event: "change",
    },
    props: {
        /* 初始值 */
        value: { default: "" },
        split: { type: String, default: "," },
        limit: { type: Number, default: 3 },
        appname: { type: String, default: "" },
    },
    data() {
        return {
            binds: [],
        };
    },
    created() {
        if (this.value) {
			if( typeof( this.value ) == 'string' ){
				this.binds = this.value.split(this.split);
			}
			else if( typeof( this.value ) == 'object' ){
				this.binds = this.value;
			}
        }
    },
    methods: {
        confirm(paths) {
            for(let index in paths){
                if( this.binds.indexOf(paths[index]) === -1 ){
                    this.binds.push(paths[index]);
                }
            }
        },
        loadImg(path){
            if(path.substring(0,4)=='http'){
                return path;
            }else{
                return $config.apihost + '/'+ path;
            }
        },
        deleteImg(index) {
            this.binds.splice(index,1);
        },
    },
    watch: {
        value(value) {
            this.binds = value ? value.split(this.split) : [];
        },
        binds(vals) {
            this.$emit("change", vals.join(this.split));
        },
    },
};
</script>
<style>
.draggable-list {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}
.draggable-list .wrapper > span {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.draggable-list .item {
    position: relative;
    width: 110px;
    height: 110px;
    margin-top: 10px;
    margin-right: 10px;
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid #dddddd;
}

.draggable-list .delete-btn {
    position: absolute;
    top: 0;
    right: 0;
    width: 16px;
    height: 16px;
    background: red;
    line-height: 16px;
    font-size: 16px;
    color: #ffffff;
    display: none;
}

.draggable-list .item:hover .delete-btn {
    display: block;
}

.draggable-list .item img {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    max-height: 100%;
    max-width: 100%;
	background-color: #22222255;
}

.draggable-list .img-select {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed #dddddd;
    font-size: 30px;
}

.draggable-list .img-select i {
    color: #409eff;
}
</style>